import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { marked } from "marked"; // Import the marked library

import { ReactComponent as QiwaLogo } from "../assets/icons/QiwaLogo.svg";
import { ReactComponent as Stars } from "../assets/icons/Stars.svg";
import { ReactComponent as Shield } from "../assets/icons/Shield.svg";
import { ReactComponent as Like } from "../assets/icons/Like.svg";
import { ReactComponent as Dislike } from "../assets/icons/Dislike.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/ChevronDown.svg";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";

import QiwaAssistantImg from "../assets/images/AI.png";
import QiwaAssistantChatImg from "../assets/images/AI-chat.png";
import GradientBg from "../assets/images/Gradient.png";
import DocModal from "./common/DocModal";
import PdfPreview from "./common/PdfPreview";

export default function ArChat() {
  const suggestions = [
    {
      title: "كيف اعمل سيرة ذاتية؟",
    },
    {
      title: "كيف يمكنني تعيين موظفين غير سعوديين ؟",
    },
    {
      title: " كيف اضيف مستخدم جديد لمنشأتي؟",
    },
  ];
  const [chat, setChat] = useState([]);
  const chatDivRef = useRef(null);
  const [hasChatStarted, sethasChatStarted] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [openPDFModal, setopenPDFModal] = useState(false);
  const [fileDoc, setfileDoc] = useState("");

  useEffect(() => {
    chatDivRef.current?.scrollTo({
      top: chatDivRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chat, chatDivRef]);

  // Automatically scroll to the bottom when new chat messages are added
  useEffect(() => {
    if (hasChatStarted) {
      chatDivRef.current?.scrollTo({
        top: chatDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [hasChatStarted]);

  // Generate conversation_id when the page loads
  useEffect(() => {
    const generateConversationId = () => {
      const randomString = Math.random().toString(36).substr(2, 9);
      setConversationId(randomString);
    };

    generateConversationId();
  }, []);

  // Handle sending user message
  const handleSendMessage = async () => {
    // console.log(conversationId);
    if (userInput.trim()) {
      setisLoading(true);

      try {
        // Send the user's input to the API
        const response = await axios.post(
          "https://qiwa-api.labeah.ai/api/chat",
          {
            prompt: userInput,
            conversation_id: conversationId,
          }
        );

        // Update chat with the bot's response
        const botResponse = response.data.response || "لا يوجد اجابة من البوت";
        setisLoading(false);
        setChat((prevChat) => [
          ...prevChat, // Remove the placeholder
          {
            userText: userInput,
            botText: formatAssistantResponse(botResponse),
            sources: response.data.source,
          },
        ]);
      } catch (error) {
        console.error("Error sending message to API:", error);
        setisLoading(false);
        setChat((prevChat) => [
          ...prevChat, // Remove the placeholder
          {
            userText: userInput,
            botText: "Failed to get a response. Try again.",
            hasFeedback: false,
          },
        ]);
      }

      // Clear the input field
      setUserInput("");
    }
  };

  function handleFeedbackBtn(index) {
    setChat((prevConversation) =>
      prevConversation.map((item, i) =>
        i === index ? { ...item, hasFeedback: true } : item
      )
    );

    toast.success("شكرًا لك على ملاحظاتك", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
  }

  // Function to format the assistant's response with bullet points or line breaks
  function formatAssistantResponse(response) {
    // Check if the response contains bullet points or new lines
    let formatted = response;

    // Replace new lines with <br> for proper HTML display
    formatted = formatted.replace(/\n/g, "<br>");

    // Optionally, if bullet points exist (e.g., numbered lists), ensure proper HTML formatting
    formatted = formatted.replace(/(\d+)\.\s/g, "<br>$1. "); // Handles numbered lists like 1., 2., etc.

    return formatted;
  }

  function encodeDoc(item) {
    // const baseUrl = "https://qiwallmfiles.s3.eu-central-1.amazonaws.com/";
    let baseUrl = "https://qiwa-api.labeah.ai/files/";
    
    let fileName = item.sources;
    if ("Labor Law".includes(item.sources)){
      fileName="50 legal questions.docx"
    }
    let pdfPattern = /\.pdf$/i; // The 'i' flag makes it case-insensitive
    let fullUrl = baseUrl + item.sources;
    console.log(fullUrl)
    // Create a temporary link element
    let downloadLink = document.createElement("a");
    downloadLink.href = fullUrl;
    downloadLink.download = fileName; // Specify the filename for download
    document.body.appendChild(downloadLink); // Append to the DOM
    downloadLink.click(); // Trigger the download
    document.body.removeChild(downloadLink); // Clean up
  }

  return (
    <div dir="rtl" className="relative bg-[#FAFAFA] min-h-screen max-h-full">
      <div className="flex justify-between mx-8 lg:mx-12 py-6 lg:py-8 z-50">
        <div className={`flex space-x-4 space-x-reverse `}>
          <QiwaLogo
            onClick={() => {
              window.location.reload();
            }}
            className="cursor-pointer"
          />
          <div className="flex my-auto bg-white shadow-md rounded-full py-2 px-4 z-30">
            <Stars className="text-primary my-auto" />
            <h4 className="text-primary px-1 pr-2 font-DroidRegular">سراج</h4>
          </div>
        </div>

        {/* <div className="z-50">
          <Dropdown />
        </div> */}
      </div>

      {hasChatStarted ? (
        <div className="grow flex flex-col justify-between max-w-4xl mx-4 lg:mx-auto h-[77vh] lg:h-[83vh] overflow-auto">
          <div ref={chatDivRef} className="flex-grow overflow-auto">
            {chat.length > 0 ? (
              chat.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl p-5 shadow-sm mb-5"
                >
                  <img src={QiwaAssistantChatImg} className="object-cover" />
                  <div className="mx-10">
                    <h1 className="text-2xl font-DroidBold mb-4">
                      {item.userText}
                    </h1>
                    <p className="text-base leading-7">
                      <div
                        // contentEditable="true"
                        className="font-DroidRegular outline-none"
                        dangerouslySetInnerHTML={{
                          __html: marked(item.botText),
                        }}
                      ></div>
                    </p>

                    {!item.hasFeedback && (
                      <div className="bg-[#F6F7F7] p-5 my-4 rounded-xl">
                        <h3 className="text-base font-DroidBold ">
                          هل هذه الإجابة مفيدة؟{" "}
                        </h3>
                        <div className="flex mt-4 space-x-5 space-x-reverse font-DroidRegular">
                          <div
                            onClick={() => handleFeedbackBtn(index)}
                            className="flex space-x-2 space-x-reverse cursor-pointer"
                          >
                            <Like className="my-auto" />
                            <h5>نعم</h5>
                          </div>
                          <div
                            onClick={() => handleFeedbackBtn(index)}
                            className="flex space-x-2 space-x-reverse cursor-pointer"
                          >
                            <Dislike className="my-auto" />
                            <h5>لا</h5>
                          </div>
                        </div>
                      </div>
                    )}

                    {item.sources && (
                      <>
                        <div className="flex space-x-2 space-x-reverse mb-4 cursor-pointer">
                          <h3 className="text-md font-DroidBold">مصادر</h3>
                          <ChevronDown
                            className={`my-auto transition-transform rotate-0`}
                          />
                        </div>

                        <div
                          className="font-DroidRegular cursor-pointer text-indigo-500 hover:underline"
                          onClick={() => {
                            encodeDoc(item);
                          }}
                        >
                          {item.sources}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center text-gray-500 h-full">
                <div className="m-auto font-DroidRegular">لا يوجد محادثة</div>
              </div>
            )}
          </div>

          {isLoading ? (
            <div className="text-primary border border-primary rounded-lg px-2 py-1 mb-1 mx-auto font-DroidRegular animate-bounce">
              تحميل ...
            </div>
          ) : null}

          {/* Input field always at the bottom */}
          <div className="sticky bottom-0 bg-white flex border rounded-xl shadow-sm p-3">
            <div className="w-full">
              <textarea
                rows={Math.max(1, userInput.split("\n").length)}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                style={{
                  maxHeight: "200px",
                  minHeight: "52px",
                }}
                className="w-full resize-none border-0 bg-transparent text-black outline-none font-DroidRegular "
              />
            </div>

            <button
              style={{
                minHeight: "52px", // Set minimum height to initial height
              }}
            >
              <PaperAirplaneIcon
                onClick={() => {
                  if (userInput === "") {
                  } else {
                    handleSendMessage();
                  }
                }}
                className={`${
                  userInput === "" ? "text-gray-300" : ""
                } h-6 w-6 rotate-180 my-auto`}
              />
            </button>
          </div>
        </div>
      ) : (
        <>
          <img
            src={GradientBg}
            className="absolute top-0 inset-x-0 mx-auto z-10"
          />

          <div className="relative max-w-7xl mx-4 lg:mx-auto z-20">
            <div className="flex flex-col justify-center">
              <img src={QiwaAssistantImg} className="mx-auto" />
              <div className="max-w-2xl mx-auto text-center mt-5">
                <h1 className="text-2xl lg:text-3xl font-DroidBold">مرحبا</h1>
                <h2 className="text-2xl lg:text-3xl font-DroidBold mt-1">
                  <span className="text-primary">
                    أنا سراج مساعدك الإفتراضي{" "}
                  </span>
                  على قوى
                </h2>
                <p className="text-[#0A0E14] text-base lg:text-base mt-2.5 font-DroidRegular">
                  أنا هنا للإرشادك إلى الإجابات التي تحتاجها ومساعدتك في استخدام
                  خدمات قوى. أرسل سؤالك وسأقدم لك المعلومات التي تحتاجها.
                </p>

                <div className="flex items-center bg-white mt-8 border rounded-xl shadow-sm">
                  <Stars className="my-auto mr-4 text-[#6C7276]" />
                  <input
                    placeholder="أدخل سؤالك هنا...."
                    className="w-full h-12 p-3 py-7 outline-none rounded-xl font-DroidRegular"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (userInput === "") {
                        } else {
                          sethasChatStarted(true);
                          handleSendMessage();
                        }
                      }
                    }}
                    onChange={(e) => setUserInput(e.target.value)}
                  />
                  <PaperAirplaneIcon
                    onClick={() => {
                      if (userInput === "") {
                      } else {
                        sethasChatStarted(true);
                        handleSendMessage();
                      }
                    }}
                    className={`${
                      userInput === "" ? "text-gray-300" : ""
                    } h-6 w-6 rotate-180 ml-4`}
                  />
                </div>

                <div className="flex mt-2 mx-6 space-x-2 space-x-reverse text-sm text-gray-500 font-DroidRegular">
                  <Shield />
                  <h5>جميع البيانات التي تشاركها آمنة وخاصة.</h5>
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 max-w-3xl mx-auto mt-5 lg:mt-20">
                {suggestions.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={async () => {
                        sethasChatStarted(true);

                        if (item.title.trim()) {
                          setisLoading(true);

                          try {
                            // Send the user's input to the API
                            const response = await axios.post(
                              "https://qiwa-api.labeah.ai/api/chat",
                              {
                                prompt: item.title,
                                conversation_id: conversationId,
                              }
                            );

                            // Update chat with the bot's response
                            const botResponse =
                              response.data.response ||
                              "لا يوجد اجابة من البوت";
                            setisLoading(false);
                            setChat((prevChat) => [
                              ...prevChat, // Remove the placeholder
                              {
                                userText: item.title,
                                botText: formatAssistantResponse(botResponse),
                                sources: response.data.source,
                              },
                            ]);
                          } catch (error) {
                            console.error(
                              "Error sending message to API:",
                              error
                            );
                            setisLoading(false);
                            setChat((prevChat) => [
                              ...prevChat, // Remove the placeholder
                              {
                                userText: item.title,
                                botText: "Failed to get a response. Try again.",
                                hasFeedback: false,
                              },
                            ]);
                          }
                        }
                      }}
                      className="bg-white text-[#0A0E14] text-sm font-DroidRegular px-7 py-5 rounded-2xl shadow cursor-pointer"
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="lg:absolute bottom-1 inset-x-0 text-center font-DroidRegular text-[#0A0E14] text-sm py-5 lg:py-0">
        تستند جميع الإجابات إلى مقالات مركز المعرفة الرسمي في قوى
      </div>

      <ToastContainer />
      <DocModal open={openPDFModal} setOpen={setopenPDFModal}>
        <PdfPreview file={fileDoc} />
      </DocModal>
    </div>
  );
}
