import { Routes, Route } from "react-router-dom";
import EnChat from "./components/EnChat";
import ArChat from "./components/ArChat";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<ArChat />} />
      <Route path="/en" element={<EnChat />} />
    </Routes>
  );
}
